import { Button } from '@mui/material';
import * as React from 'react';
import { useDataStore } from '../state';

export default function Upload() {
	const setInitialData = useDataStore((state) => state.setInitialData);
	const handleFileChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const file = event.target.files[0];
			// SEND POST REQUEST TO SERVER
			// localhost:8000/upload
			let formData = new FormData();
			formData.append('excel_file', file);
			fetch(
				process.env.PUBLIC_URL +
					'https://hackathon.6id3vofcaf3iq.us-east-1.cs.amazonlightsail.com/upload/',
				{
					method: 'POST',
					body: formData,
					mode: 'no-cors',
					headers: {
						'Access-Control-Allow-Origin': '*',
						// 'Content-Type': 'mulipart/form-data',
						// Accept: 'application/json',
					},
				}
			)
				.then((res) => {
					return res.json();
				})
				.then((data) => {
					setInitialData(data);
					event.target.value = ''; // reset file input
				});
		}
	};
	return (
		<Button variant="contained" color="primary" component="label">
			Upload spreadsheet
			<input type="file" hidden accept=".xls, .xlsx" onChange={handleFileChangeEvent} />
		</Button>
	);
}
