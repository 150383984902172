export const navLabels = [
	'Courses',
	'Study Tracks',
	'Teachers',
	'Classrooms',
	'Students',
	'----',
	'Schedule Solution',
	'Student Solution',
] as const;

export type NavKey = typeof navLabels[number];
