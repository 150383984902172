import _ from 'lodash';
import { Lecture, SolutionDataState, Student } from './state';

export function getLectureByName(lectureName: string, solution: SolutionDataState): Lecture {
	return solution.Lectures[lectureName];
}

export function getLecturesOfStudent(student: Student, solution: SolutionDataState): Lecture[] {
	return student.Lectures?.map((lectureName) => getLectureByName(lectureName, solution))!.sort(
		(a, b) => a.timeSlot.start - b.timeSlot.start
	)!;
}

export function getStudentByName(studentName: string, solution: SolutionDataState): Student {
	return solution.Students[studentName];
}

export function getAllStudentsInLecture(
	lectureName: string,
	solution: SolutionDataState
): Student[] {
	return _.filter(solution.Students, (student) => student.Lectures.includes(lectureName));
}

export type Valueof<T> = T[keyof T]
