import * as React from 'react';
import _ from 'lodash';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';

import { navLabels, NavKey } from '../nav';
import Upload from './Upload';
import Solve from './SolveBtn';

const drawerWidth = 240;

export default function NavDrawer({
	active,
	onChange,
}: {
	active: NavKey;
	onChange: (newKey: NavKey) => void;
}) {
	return (
		<Drawer
			sx={{
				width: drawerWidth,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: drawerWidth,
					boxSizing: 'border-box',
				},
			}}
			variant="permanent"
			anchor="left"
		>
			<Toolbar />
			<Divider />
			<List>
				<ListItem>
					<Upload />
				</ListItem>

				{_.map(navLabels, (label: NavKey, index: number) => (
					label === '----' ? <Divider key={index} /> : (
						<ListItem key={index} disablePadding>
							<ListItemButton onClick={() => onChange(label)} selected={active === label}>
								<ListItemIcon>
									<InboxIcon />
								</ListItemIcon>
								<ListItemText primary={label} />
							</ListItemButton>
						</ListItem>
					)
				))}
				<ListItem>
					<Solve focusView={() => onChange("Schedule Solution")} />
				</ListItem>
			</List>
		</Drawer>
	);
}
