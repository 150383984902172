import * as React from 'react';
import _ from 'lodash';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { useDataStore, Classroom } from '../state';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

function ClassroomItem({ roomname, classroom }: { roomname: string; classroom: Classroom }) {
	const classrooms = useDataStore((state) => state.Classrooms);

	return (
		<>
			<ListItem>
				<ListItemText primary={roomname} />
				<FormGroup style={{ alignItems: 'center' }}>
					<FormControlLabel
						control={
							<Checkbox
								defaultChecked={classroom.Whiteboard}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									classrooms[roomname].Whiteboard = event.target.checked;
								}}
							/>
						}
						label="Whiteboard"
					/>
					<FormControlLabel
						control={
							<Checkbox
								defaultChecked={classroom.Projector}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									classrooms[roomname].Projector = event.target.checked;
								}}
							/>
						}
						label="Projector"
					/>
				</FormGroup>
			</ListItem>
		</>
	);
}

export default function Courses() {
	const classrooms = useDataStore((state) => state.Classrooms);
	return (
		<List dense>
			{_.map(classrooms, (classroomData, room) => (
				<ClassroomItem key={room} roomname={room} classroom={classroomData} />
			))}
		</List>
	);
}
