import React from 'react';
import _ from 'lodash';
import { Lecture, SolutionDataState, Student } from '../state';
import { Grid, Card, Typography, CardContent, Box } from '@mui/material';
import { getAllStudentsInLecture } from '../utils';

export function StudentGridComp({
	student,
	selectedLecture,
	solution,
	updateStudent,
}: {
	student: Student;
	selectedLecture: Lecture;
	solution: SolutionDataState;
	updateStudent: (student: Student) => void;
}) {
	return (
		<Grid container spacing={2}>
			{_.map(getAllStudentsInLecture(selectedLecture.name, solution), (classmate) => {
				return (
					<StudentCardComp
						updateStudent={updateStudent}
						student={classmate}
						key={classmate.Name}
						selectedStudent={student}
					/>
				);
			})}
		</Grid>
	);
}

function getColorByStudentComparison(student: Student, selectedStudent: Student) {
	if (student.Name === selectedStudent.Name) {
		return '#e0e0e0';
	} else if (selectedStudent.Enemies!.includes(student.Name!)) {
		return '#ffcccc';
	} else if (selectedStudent.Friends!.includes(student.Name!)) {
		return '#ccffcc';
	}
}

function StudentCardComp({
	student,
	selectedStudent,
	updateStudent,
}: {
	student: Student;
	selectedStudent: Student;
	updateStudent: (student: Student) => void;
}) {
	const handleStudentClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		student: Student
	) => {
		updateStudent(student);
	};
	return (
		<Grid item xs={12} sm={6} md={4} lg={3} key={student.Name} alignItems={'center'}>
			{/* Square card with anonymous user icon image and student name */}
			<Card
				sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
				onClick={(e) => handleStudentClick(e, student)}
				variant={student.Name === selectedStudent.Name ? 'outlined' : 'elevation'}
				style={{ backgroundColor: getColorByStudentComparison(student, selectedStudent) }}
			>
				<CardContent>
					<Typography variant="h6" component="h2" sx={{ flexGrow: 1, textAlign: 'center' }}>
						{student.Name} {student.Name![0]}.
					</Typography>
					<Box display="flex" justifyContent="center" alignItems="center">
						{/* <Box
							// Set color of student icon based on city
							sx={{
								background: 
								borderRadius: '50%',
								width: 7,
								height: 7,
								margin: '3px',
							}}
						/> */}
						<Box
							// Set color of student icon based on student's gender
							sx={{
								background: student.Gender === 0 ? '#4287f5' : '#eb4d87',
								borderRadius: '50%',
								width: 7,
								height: 7,
								margin: '3px',
							}}
						/>
					</Box>
				</CardContent>
			</Card>
		</Grid>
	);
}
