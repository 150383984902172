import React from 'react';
import _ from 'lodash'
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { SelectChangeEvent } from '@mui/material/Select';

import { useDataStore } from '../state';
import { MenuItem, TextField } from '@mui/material';

export default function CourseSelector(
    { courses, onChange, label="Courses" }:
    { courses: string[], onChange: (courses: string[]) => void, label?: string }
) {
    const allCourses = useDataStore((state) => _.keys(state.Courses));
    const handleChange = (event: SelectChangeEvent<typeof courses>) => {
        const {
            target: { value },
        } = event;
        onChange(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };
    return (
        <TextField
            size='small'
            margin='normal'
            select
            label={label}
            value={courses}
            SelectProps={{
                multiple: true,
                displayEmpty: true,
                // @ts-ignore
                onChange: handleChange,
                renderValue: (selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, minWidth: 150 }}>
                        {(selected as typeof courses).map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )
            }}
        >
            {allCourses.map(course => (
                <MenuItem key={course} value={course}>{course}</MenuItem>
            ))}
        </TextField>
    )
}
