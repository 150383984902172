import React, { useState } from 'react';
import _ from 'lodash'
import { Collapse, IconButton, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import {
    AddCircle as AddIcon,
    RemoveCircle as RemoveIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { Valueof } from '../utils';

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'] as const;
type DayName = typeof DAYS[number];
function DayDropdown({days, onChange}: {days: DayName[], onChange: (days: DayName[]) => void}) {
    return (
        <Select multiple value={days} onChange={e => onChange(e.target.value as DayName[])} size="small" margin='dense'>
            {DAYS.map((day, i) => (
                <MenuItem key={i} value={day}>{day}</MenuItem>
            ))}
        </Select>
    )
}

const CAN_VALS = {
    CANNOT: 0,
    CAN: 1,
    CAN_ONLY: 2
} as const;
export type TeacherConstraintValue = {
    createTime: number,
    can: Valueof<typeof CAN_VALS>,
    days: DayName[]
}
export function TeacherConstraint(
    {teacherName, constraintValue, onChange}:
    {teacherName: string, constraintValue: TeacherConstraintValue|null, onChange: (val: TeacherConstraintValue|null) => void}
) {
    const constraint = constraintValue || { can: CAN_VALS.CAN, days: [], createTime: Date.now() };
    return (
        <Paper sx={{p:1}}>
            <Stack direction="row" spacing={1} alignItems="center">
                <IconButton onClick={() => onChange(null)}><RemoveIcon /></IconButton>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} style={{ display: 'inline-block' }}>{teacherName}</Typography>
                <Select value={constraint.can} size="small" margin='dense' onChange={e => onChange({ ...constraint, can: e.target.value as typeof constraint.can})} >
                    <MenuItem value={CAN_VALS.CAN}>can</MenuItem>
                    <MenuItem value={CAN_VALS.CANNOT}>can't</MenuItem>
                    <MenuItem value={CAN_VALS.CAN_ONLY}>can only</MenuItem>
                </Select>
                <Typography component="span" variant="body2">teach on</Typography>
                <DayDropdown days={constraint.days} onChange={days => onChange({...constraint, days})} />
            </Stack>
        </Paper>
    )
}

export function TeacherConstraints(
    { teacherName, constraints, onChange }:
        { teacherName: string, constraints: TeacherConstraintValue[], onChange: (val: TeacherConstraintValue|null, index: number) => void }
) {
    const [open, setOpen] = useState(false);
    const [numConstraints, setNumConstraints] = useState(constraints.length);
    return (
        <Paper sx={{ p: 1 }}>
            <Stack direction="row" alignItems="center" onClick={() => setOpen(!open)}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', cursor: 'pointer' }}>Constraints</Typography>
                <IconButton>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
            </Stack>
            <Collapse in={open}>
                {_.range(numConstraints).map(i => {
                    const constraint = constraints[i];
                    return <TeacherConstraint
                        key={constraint?.createTime || i}
                        teacherName={teacherName}
                        constraintValue={constraint}
                        onChange={val => {
                            onChange(val, i);
                            if (val === null) setNumConstraints(n => n-1);
                        }}
                    />
                })}
                <IconButton onClick={() => setNumConstraints(n=>n+1)}><AddIcon /></IconButton>
            </Collapse>
        </Paper>
    )
}
