import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSolutionStore, SolutionDataState, LectureCard, LOAD_STATES } from '../state';
import SolveBtn from './SolveBtn';

import Box from '@mui/material/Box';
import {
	Card,
	CardContent,
	Divider,
	Paper,
	Stack,
	Tabs,
	Tab,
	Typography,
	TextField,
	MenuItem,
	Button,
	Skeleton,
} from '@mui/material';

const tabs = {
	overview: 'Overview',
	studyTrack: 'Study Track',
	teachers: 'Teacher',
	students: 'Student',
	classrooms: 'Classroom',
};
type TabKey = keyof typeof tabs;

function solutionToCards(solution: SolutionDataState): LectureCard[] {
	let cards: LectureCard[] = _.map(solution.Lectures, (lecture, lectureId) => {
		let lectureCard: LectureCard = {
			name: lecture.name,
			course: lecture.course,
			teacher: lecture.teacher,
			classroom: lecture.classroom,
			timeSlot: lecture.timeSlot,
			students: lecture.studentNames.map((name) => solution.Students[name]),
		};
		return lectureCard;
	});
	return cards.sort((a, b) => a.timeSlot.start - b.timeSlot.start);
}

function minutesToTime(minutes: number) {
	let hours = Math.floor(minutes / 60);
	let mins = minutes % 60;
	// Add leading zeros (first convert to string)
	let hoursStr = hours.toString();
	let minsStr = mins.toString();
	if (hours < 10) {
		hoursStr = '0' + hoursStr;
	}
	if (mins < 10) {
		minsStr = '0' + minsStr;
	}
	return `${hoursStr}:${minsStr}`;
}

type SetFilterFunc = (tab: TabKey, filter: string) => void;

function LectureCardComp({ card, setFilter }: { card: LectureCard; setFilter: SetFilterFunc }) {
	return (
		<Card variant="elevation">
			<CardContent>
				<Typography variant="h6">{card.course}</Typography>
				<Divider sx={{ my: 1 }} />
				<Typography
					variant="subtitle1"
					style={{ fontWeight: 550 }}
					onClick={() => setFilter('teachers', card.teacher)}
					sx={{ cursor: 'pointer' }}
				>
					Dr. {card.teacher}
				</Typography>
				<Typography variant="subtitle2">
					{minutesToTime(card.timeSlot.start)} - {minutesToTime(card.timeSlot.end)}
				</Typography>
				<Typography
					variant="subtitle2"
					onClick={() => setFilter('classrooms', card.classroom)}
					sx={{ cursor: 'pointer' }}
				>
					Classroom {card.classroom}
				</Typography>
			</CardContent>
		</Card>
	);
}

function DayView({ cards, setFilter }: { cards: LectureCard[]; setFilter: SetFilterFunc }) {
	const loadState = useSolutionStore((state) => state.loadState);
	return (
		<Stack direction="column" spacing={1}>
			{cards.map((card) => (
				loadState === LOAD_STATES.FETCHING ? (
					<Skeleton key={card.name}>
						<LectureCardComp key={card.name} card={card} setFilter={setFilter} />
					</Skeleton>
				) : (
					<LectureCardComp key={card.name} card={card} setFilter={setFilter} />
				)
			))}
		</Stack>
	);
}

function WeekView({ cards, setFilter }: { cards: LectureCard[]; setFilter: SetFilterFunc }) {
	const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
	return (
		<Stack direction="row" spacing={1}>
			{days.map((day, dayIndex) => (
				<Paper key={dayIndex} elevation={1}>
					<Typography variant="subtitle2" sx={{ fontWeight: 800, p: 1 }}>
						{day}
					</Typography>
					<DayView
						cards={cards.filter((card) => card.timeSlot.day === dayIndex + 1)}
						setFilter={setFilter}
					/>
				</Paper>
			))}
		</Stack>
	);
}

function Filter({
	tab,
	activeFilter,
	options,
	onChange,
}: {
	tab: string;
	activeFilter: string;
	options: string[];
	onChange: (filter: string) => void;
}) {
	return (
		<TextField
			select
			size="small"
			margin="normal"
			label={tab}
			value={activeFilter}
			onChange={(e) => onChange(e.target.value)}
			sx={{ minWidth: 150 }}
		>
			{options.map((opt) => (
				<MenuItem key={opt} value={opt}>
					{opt}
				</MenuItem>
			))}
		</TextField>
	);
}

function filterCards(
	cards: LectureCard[],
	filterKey: string,
	activeFilter: string,
	data: SolutionDataState
) {
	return cards.filter((card) => {
		if (filterKey === 'overview') return true;
		if (filterKey === 'studyTrack') {
			let track = data.StudyTracks[activeFilter];
			return track.Courses.includes(card.course);
		}
		if (filterKey === 'teachers') return card.teacher === activeFilter;
		if (filterKey === 'students') {
			let student = data.Students[activeFilter];
			return student.Lectures.includes(card.name);
		}
		if (filterKey === 'classrooms') return card.classroom === activeFilter;
		return false;
	});
}

function Solution({ solution }: { solution: SolutionDataState }) {
	const loadState = useSolutionStore((state) => state.loadState);
	const cards = solutionToCards(solution);
	const [tab, setTab] = useState('overview' as TabKey);
	const [activeFilter, setActiveFilter] = useState('');
	const [filterOptions, setFilterOptions] = useState([] as string[]);
	const selectTab = (newTab: TabKey) => {
		setTab(newTab);
		if (newTab === 'overview') return;
		let key = (
			{
				studyTrack: 'StudyTracks',
				teachers: 'Teachers',
				students: 'Students',
				classrooms: 'Classrooms',
			} as const
		)[newTab];
		let keys = _.keys(solution[key]);
		setFilterOptions(keys);
		setActiveFilter(keys[0]);
	};
	const selectActiveTabAndFilter = (newTab: TabKey, newFilter: string) => {
		selectTab(newTab);
		setActiveFilter(newFilter);
	};
	console.log(cards);
	const filteredCards = filterCards(cards, tab, activeFilter, solution);

	return (
		<>
			{loadState === LOAD_STATES.DONE && (<>
				<Tabs value={tab} onChange={(e, newValue) => selectTab(newValue)}>
					{_.map(tabs, (label, tab) => (
						<Tab key={tab} label={label} value={tab} />
					))}
				</Tabs>
				{tab !== 'overview' && (
					<Filter
						tab={tabs[tab]}
						activeFilter={activeFilter}
						options={filterOptions}
						onChange={(filter) => setActiveFilter(filter)}
					/>
				)}
				<Divider />
			</>)}
			<WeekView cards={filteredCards} setFilter={selectActiveTabAndFilter} />
		</>
	);
}

const makeFakeLectures = (count: number) => _.fromPairs(_.range(count).map(i => {
	let start = Math.random() * 1500;
	return ['Lecture' + i, {
		"name": 'Lecture' + i,
		"teacher": "",
		"classroom": "",
		"course": "",
		studentNames: [],
		timeSlot: {
			"start": start,
			"end": start + 60,
			"day": Math.round(Math.random() * 6)
		}
	}];
}));
export default function SolutionContainer() {
	const [solved, loadState, solution] = useSolutionStore((state) => [state.solved, state.loadState, state.solution]);
	const [solutionSkeletonData, setSkeleton] = useState({
		interval: 0,
		skeleton: {
			Courses: {},
			// @ts-ignore
			Lectures: makeFakeLectures(8) as SolutionDataState['Lectures'],
			Classrooms: {},
			Teachers: {},
			Students: {},
			StudyTracks: {},
		} as SolutionDataState
	});
	const isFetching = loadState === LOAD_STATES.FETCHING;
	useEffect(() => {
		if (isFetching && !solutionSkeletonData.interval) {
			const iv = setInterval(() => {
				setSkeleton(sk => ({...sk, skeleton: {...sk.skeleton, Lectures: makeFakeLectures(8)}}))
			}, 2500);
			// @ts-ignore
			setSkeleton(sk => ({...sk, interval: iv}))
		} else {
			clearInterval(solutionSkeletonData.interval);
			setSkeleton(sk => ({ ...sk, interval: 0 }))
		}
	}, [isFetching, solutionSkeletonData.interval])
	return (
		<Box>
			{!solved && <SolveBtn />}
			{isFetching && <Solution solution={solutionSkeletonData.skeleton} />}
			{!!solution && !isFetching && <Solution solution={solution as SolutionDataState} />}
		</Box>
	);
}
