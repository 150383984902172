import React from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useDataStore, StudyTrack } from '../state';
import CourseSelector from './CourseSelector';

function STItem({ track, trackData }: { track: string; trackData: StudyTrack }) {
	const setStudyTrack = useDataStore((state) => state.setStudyTrack);
	return (
		<Box sx={{ m: 0.4 }}>
			<Typography variant="subtitle1">{track}</Typography>
			<Box>
				<CourseSelector courses={trackData.Courses} onChange={courses => setStudyTrack(track, courses)} label="" />
			</Box>
		</Box>
	);
}

export default function StudyTracks() {
	const tracks = useDataStore((state) => state.StudyTracks);
	return (
		<Box>
			{_.map(tracks, (trackData, track) => (
				<STItem key={track} track={track} trackData={trackData} />
			))}
		</Box>
	);
}
