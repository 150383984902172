import React from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useDataStore, Student } from '../state';
import CourseSelector from './CourseSelector';
import { MenuItem, TextField } from '@mui/material';
import FriendEnemySelector from './FriendEnemySelector';

function StudentItem({ name, student }: { name: string; student: Student }) {
	const allTracks = useDataStore((state) => _.keys(state.StudyTracks));
	const setStudent = useDataStore((state) => state.setStudent);
	return (
		<Box sx={{ m: 0.4 }}>
			<Typography variant="subtitle1">{name || 'Undefinde'}</Typography>
			<TextField
				select
				size="small"
				margin="normal"
				label="Study Track"
				value={student.StudyTrack}
				onChange={(e) => setStudent(name, { StudyTrack: e.target.value })}
			>
				{allTracks.map((track) => (
					<MenuItem key={track} value={track}>
						{track}
					</MenuItem>
				))}
			</TextField>
			<Box>
				<CourseSelector
					courses={student.Courses}
					onChange={(Courses) => setStudent(name, { Courses })}
				/>
			</Box>
			<Box>
				<FriendEnemySelector
					students={student.Friends!}
					label={'Together with'}
					updateStudentPropByName={(students) => setStudent(name, { Friends: students })}
				/>
			</Box>
			<Box>
				<FriendEnemySelector
					students={student.Enemies!}
					label={'Separate from'}
					updateStudentPropByName={(students) => setStudent(name, { Enemies: students })}
				/>
			</Box>
		</Box>
	);
}

export default function Students() {
	const students = useDataStore((state) => _.values(state.Students));
	const [loadAll, setLoadAll] = React.useState(false);
	React.useEffect(() => {
		setTimeout(() => setLoadAll(true), 5);
	}, []);
	return (
		<Box>
			{_.map(students.slice(0, loadAll ? -1 : 10), (student) => (
				<StudentItem key={student.Name} name={student.Name} student={student} />
			))}
		</Box>
	);
}
