import { State, StateCreator, StoreMutatorIdentifier } from 'zustand';

type Logger = <
	T extends State,
	Mps extends [StoreMutatorIdentifier, unknown][] = [],
	Mcs extends [StoreMutatorIdentifier, unknown][] = []
>(
	f: StateCreator<T, Mps, Mcs>,
	name?: string
) => StateCreator<T, Mps, Mcs>;

type LoggerImpl = <T extends State>(
	f: StateCreator<T, [], []>,
	name?: string
) => StateCreator<T, [], []>;

const loggerImpl: LoggerImpl = (f, name) => (set, get, store) => {
	const loggedSet: typeof set = (...a) => {
		set(...a);
		console.log(...(name ? [`${name}:`] : []), get());
	};
	store.setState = loggedSet;

	return f(loggedSet, get, store);
};

export const logger = loggerImpl as unknown as Logger;

const timestampImpl: LoggerImpl = (f, name) => (set, get, store) => {
	const timestamppedSet: typeof set = (...a) => {
		set(...a);
		// @ts-ignore
		set({ timestamp: Date.now() });
		console.log(...(name ? [`${name}:`] : []), get());
	};
	store.setState = timestamppedSet;

	return f(timestamppedSet, get, store);
};

export const timestamp = timestampImpl as unknown as Logger;
