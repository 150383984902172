import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Courses from './Courses';
import StudyTracks from './StudyTracks';
import Teachers from './Teachers';
import Classrooms from './Classrooms';
import Students from './Students';
import ScheduleSolution from './ScheduleSolution';
import StudentSolution from './StudentSolution';

import { NavKey } from '../nav';

const navComponents = {
	Courses: Courses,
	'Study Tracks': StudyTracks,
	Teachers: Teachers,
	Classrooms: Classrooms,
	Students: Students,
	'----': Box,
	'Schedule Solution': ScheduleSolution,
	'Student Solution': StudentSolution,
};

export default function NavMain({ activeTab }: { activeTab: NavKey }) {
	const ActiveComponent = navComponents[activeTab];
	return (
		<Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
			<Toolbar />
			<ActiveComponent />
		</Box>
	);
}
