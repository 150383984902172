import React, { useState } from 'react';
import _ from 'lodash';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import {
	Add as AddIcon,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';

import { useDataStore, Course } from '../state';
import { Collapse, TextField, Paper } from '@mui/material';

function CourseItem({ course, name }: { course: Course; name: string }) {
	return (
		<ListItem>
			<ListItemText primary={name} secondary={'Length: ' + course.Length + ' mins'} />
		</ListItem>
	);
}

export default function Courses() {
	const courses = useDataStore((state) => state.Courses);
	const setCourse = useDataStore((state) => state.setCourse);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	return (
		<>
			<Button
				variant="contained"
				color="secondary"
				startIcon={dropdownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				onClick={() => setDropdownOpen(!dropdownOpen)}
			>
				Add Course
			</Button>
			<AddCourse open={dropdownOpen} onSubmit={setCourse} />
			<List dense>
				{_.map(courses, (course, name) => (
					<CourseItem key={name} course={course} name={name} />
				))}
			</List>
		</>
	);
}

function AddCourse({ open, onSubmit }: { open: boolean; onSubmit: (formState: Course) => void }) {
	const [formState, setFormState] = useState({
		Name: '',
		Length: 60,
		MaxStudents: 20,
		Frequency: 1,
	} as Course);
	const submit = () => {
		onSubmit(formState);
	};
	return (
		<Collapse in={open}>
			<Paper elevation={1} sx={{ m: 1, p: 1 }}>
				<TextField
					margin="normal"
					label="name"
					value={formState.Name}
					onChange={(e) => setFormState({ ...formState, Name: e.target.value })}
				/>
				<TextField
					margin="normal"
					label="length"
					value={formState.Length}
					onChange={(e) => setFormState({ ...formState, Length: +e.target.value })}
				/>
				<TextField
					margin="normal"
					label="maxStudents"
					value={formState.MaxStudents}
					onChange={(e) => setFormState({ ...formState, MaxStudents: +e.target.value })}
				/>
				{/* <TextField margin='normal' label="frequency" value={formState.Frequency} onChange={e => setFormState({ ...formState, Frequency: +e.target.value })} /> */}
				<Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={submit}>
					Add Course
				</Button>
			</Paper>
		</Collapse>
	);
}
