import React from 'react';
import _ from 'lodash'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useDataStore, Teacher } from '../state';
import CourseSelector from './CourseSelector';
import { Divider } from '@mui/material';
import { TeacherConstraints } from './CustomConstraint';

function TeacherItem({ name, teacher }: { name: string, teacher: Teacher }) {
	const [setTeacher, setTeacherConstraint] = useDataStore((state) => [state.setTeacher, state.setTeacherConstraint]);
	return (
		<Box sx={{ m: 0.4 }}>
			<Typography variant='h6'>{name}</Typography>
			<Box>
				<CourseSelector courses={teacher.Courses} onChange={Courses => setTeacher(name, { Courses })} label="Courses taught" />
				<TeacherConstraints teacherName={name} constraints={teacher.constraints || []} onChange={(constraint, i) => setTeacherConstraint(name, constraint, i)} />
			</Box>
		</Box>
	)
}

export default function Teachers() {
	const teachers = useDataStore(state => _.toPairs(state.Teachers))
	return (
		<Box>
			{_.map(teachers, ([name, teacher], i) => (<React.Fragment key={name}>
				<TeacherItem name={name} teacher={teacher} />
				{i !== teachers.length-1 && <Divider/>}
			</React.Fragment>))}
		</Box>
	)
}
