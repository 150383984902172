import _ from 'lodash';
import React, { useState } from 'react';
import { useSolutionStore, SolutionDataState, Student } from '../state';
import SolveBtn from './SolveBtn';

import Box from '@mui/material/Box';
import { Stack, Tabs, Tab, TextField, MenuItem, Typography, Button } from '@mui/material';
import { StudentGridComp } from './StudentGridComp';
import {
	getStudentByName,
	getLectureByName,
	getLecturesOfStudent,
	getAllStudentsInLecture,
} from '../utils';

function Filter({
	tab,
	lectureFilter,
	options,
	onChange,
}: {
	tab: string;
	lectureFilter: string;
	options: string[];
	onChange: (filter: string) => void;
}) {
	return (
		<TextField
			select
			size="small"
			margin="normal"
			label={tab}
			value={lectureFilter}
			onChange={(e) => onChange(e.target.value)}
			sx={{ minWidth: 150 }}
		>
			{options.map((opt) => (
				<MenuItem key={opt} value={opt}>
					{opt}
				</MenuItem>
			))}
		</TextField>
	);
}

function StudentSolution({ solution }: { solution: SolutionDataState }) {
	const [selectedStudent, setSelectedStudent] = useState(
		getStudentByName(_.keys(solution.Students)[0], solution)
	);
	const [selectedStudentLecture, setSelectedStudentLecture] = useState(
		getLectureByName(solution.Students[selectedStudent.Name!].Lectures[0], solution)
	);

	// Make a dropdown of all students
	const studentOptions = _.keys(solution.Students);
	const selectStudent = (student: string) => {
		setSelectedStudent(getStudentByName(student, solution));
		// Reset the selected lecture only if the lecture is not in the new student's lectures
		if (!solution.Students[student].Lectures.includes(selectedStudentLecture.name))
			setSelectedStudentLecture(getLectureByName(solution.Students[student].Lectures[0], solution));
	};
	let studentNamesInLecture: string[] = getAllStudentsInLecture(
		selectedStudentLecture.name,
		solution
	).map((student) => student.Name!);

	return (
		<>
			<Stack direction="row" spacing={1}>
				<Filter
					tab="Students"
					lectureFilter={selectedStudent.Name!}
					options={studentOptions}
					onChange={selectStudent}
				/>
			</Stack>
			<Tabs
				value={selectedStudentLecture}
				onChange={(e, newValue) => setSelectedStudentLecture(newValue)}
			>
				{!!selectedStudent &&
					// Get all lectures for the selected student
					_.map(getLecturesOfStudent(selectedStudent, solution), (lecture) => (
						<Tab key={lecture.name} label={lecture.course} value={lecture} />
					))}
			</Tabs>
			<StudentGridComp
				student={selectedStudent}
				selectedLecture={selectedStudentLecture}
				solution={solution}
				updateStudent={(student: Student) => setSelectedStudent(student)}
			/>
			<br />
			<br />
			<Typography variant="h5" sx={{ fontWeight: 'bold' }} style={{ display: 'inline-block' }}>
				Preferably with: &nbsp;
			</Typography>
			{_.map(selectedStudent.Friends, (friend) => {
				return (
					<Button
						onClick={() => {
							setSelectedStudent(getStudentByName(friend, solution));
						}}
						variant={studentNamesInLecture.includes(friend) ? 'contained' : 'outlined'}
						// disabled={!studentNamesInLecture.includes(friend)}
						color={'success'}
					>
						{friend}
					</Button>
				);
			})}
			<br />
			<br />
			<Typography variant="h5" sx={{ fontWeight: 'bold' }} style={{ display: 'inline-block' }}>
				Preferably not with: &nbsp;
			</Typography>
			{_.map(selectedStudent.Enemies, (enemy) => {
				return (
					<Button
						onClick={() => {
							setSelectedStudent(getStudentByName(enemy, solution));
						}}
						variant={studentNamesInLecture.includes(enemy) ? 'contained' : 'outlined'}
						// disabled={!studentNamesInLecture.includes(enemy)}
						color={'error'}
					>
						{enemy}
					</Button>
				);
			})}
		</>
	);
}

export default function SolutionContainer() {
	const [solved, solution] = useSolutionStore((state) => [state.solved, state.solution]);
	return (
		<Box>
			{!solved ? <SolveBtn /> : <StudentSolution solution={solution as SolutionDataState} />}
		</Box>
	);
}
