import React from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { SelectChangeEvent } from '@mui/material/Select';

import { Student, useDataStore } from '../state';
import { MenuItem, TextField } from '@mui/material';

export default function FriendEnemySelector({
	students,
	updateStudentPropByName: onChange,
	label,
}: {
	students: string[];
	updateStudentPropByName: (students: string[]) => void;
	label: string;
}) {
	const allStudents = useDataStore((state) => _.keys(state.Students));
	const handleChange = (event: SelectChangeEvent<typeof students>) => {
		const value = event.target.value as typeof students | string;
		if (typeof value === 'string') onChange(value.split(','));
		else onChange(value);
	};
	return (
		<TextField
			size="small"
			margin="normal"
			select
			label={label}
			value={students}
			SelectProps={{
				multiple: true,
				displayEmpty: true,
				// @ts-ignore
				onChange: handleChange,
				renderValue: (selected) => (
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, minWidth: 150 }}>
						{(selected as typeof students).map((value) => (
							<Chip key={value} label={value} />
						))}
					</Box>
				),
			}}
		>
			{allStudents.map((course) => (
				<MenuItem key={course} value={course}>
					{course}
				</MenuItem>
			))}
		</TextField>
	);
}
