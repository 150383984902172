import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import NavDrawer from './components/NavDrawer'
import TopBar from './components/TopBar'
import NavMain from './components/NavMain'

import { NavKey } from './nav'

function App() {
    let [navState, setNavState] = useState('Courses' as NavKey);
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <TopBar navTab={navState} />
            <NavDrawer active={navState} onChange={setNavState} />
            <NavMain activeTab={navState} />
        </Box>
    )
}

export default App;
