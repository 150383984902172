import * as React from 'react';
import { Box, Button, Icon } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import _ from 'lodash';
import { useDataStore, useSolutionStore, LOAD_STATES } from '../state';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import StartIcon from '@mui/icons-material/Start';

function onDownload() {
	// Create a dummy xlsx file
	const dummy = new Blob([''], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});
	const url = URL.createObjectURL(dummy);
	const a = document.createElement('a');
	a.setAttribute('href', url);
	a.setAttribute('download', 'solution.xlsx');
	a.click();
}

export default function Solve({ focusView }: { focusView?: () => void }) {
	const dataStore = useDataStore((state) =>
		_.pick(state, ['Classrooms', 'Courses', 'StudyTracks', 'Teachers', 'Students'])
	);
	const [loadState, setLoadState] = useSolutionStore((state) => [
		state.loadState,
		state.updateLoadState,
	]);
	const initResponse = useSolutionStore((state) => state.initResponse);
	const retrieveSolution = () => {
		setLoadState(LOAD_STATES.FETCHING);
		if (focusView) focusView();
		// SEND POST REQUEST TO SERVER
		// localhost:8000/upload
		fetch(process.env.PUBLIC_URL + '/solve/', {
			method: 'POST',
			body: JSON.stringify(dataStore),
			mode: 'no-cors',
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				console.log(data);
				initResponse(data);
			})
			.catch((error) => {
				console.error('solve error', error);
				setLoadState(LOAD_STATES.ERROR);
			});
	};
	const solved = useSolutionStore((state) => state.solved);
	return (
		<Box>
			{true || !solved ? (
				<LoadingButton
					loading={loadState === LOAD_STATES.FETCHING}
					loadingPosition="start"
					variant="contained"
					color={loadState === LOAD_STATES.ERROR ? 'error' : 'primary'}
					startIcon={<StartIcon />}
					// component="label"
					onClick={retrieveSolution}
				>
					GET SOLUTION
				</LoadingButton>
			) : (
				<Button
					variant="contained"
					color="primary"
					component="label"
					endIcon={<SaveAltIcon />}
					onClick={onDownload}
				>
					Export Solution
				</Button>
			)}
		</Box>
	);
}
