import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { logger, timestamp } from './zustandMiddleware';
import _ from 'lodash';
import { Valueof } from './utils';
import { TeacherConstraintValue } from './components/CustomConstraint';

export type TimeSlot = {
	start: number;
	end: number;
	day: number;
};

export type Lecture = {
	name: string;
	course: string;
	teacher: string;
	classroom: string;
	timeSlot: TimeSlot;
	studentNames: string[];
};
export type LectureCard = {
	name: string;
	course: string;
	teacher: string;
	classroom: string;
	timeSlot: TimeSlot;
	students: Student[];
};

export type Student = {
	Name: string;
	Lectures?: string[];
	StudyTrack: string;
	Courses: string[];
	Friends: string[];
	Enemies: string[];
	City: string;
	Gender: number;
};

export type Course = {
	Name: string;
	MaxStudents: number;
	Length: number;
	Frequency: number;
};
export type StudyTrack = {
	Courses: string[];
};

export type Teacher = {
	Courses: string[];
	constraints?: TeacherConstraintValue[];
};

export type Classroom = {
	Whiteboard: boolean;
	Projector: boolean;
};

export type DataState = {
	Courses: { [course: string]: Course };
	StudyTracks: { [track: string]: StudyTrack };
	Teachers: { [name: string]: Teacher };
	Classrooms: { [room: string]: Classroom };
	Students: { [name: string]: Student };
};

export interface StoreData extends DataState {
	timestamp: number;
	setInitialData: (data: DataState) => void;
	setCourse: (course: Course) => void;
	setStudyTrack: (track: string, courses: string[]) => void;
	setStudent: (student: string, data: Partial<Student>) => void;
	setTeacher: (teacher: string, data: Partial<Teacher>) => void;
	setTeacherConstraint: (teacher: string, constraint: TeacherConstraintValue | null, index: number) => void;
}

export const useDataStore = create<StoreData>()(
	logger(
		timestamp(
			// @ts-ignore
			immer((set) => ({
				Courses: {},
				StudyTracks: {},
				Teachers: {},
				Classrooms: {},
				Students: {},
				timestamp: Date.now(),
				setInitialData: (data) => {
					_.forEach(data.Courses, (courseData, courseName) => {
						courseData.Name = courseName;
					});
					_.forEach(data.Students, (studentData, studentName) => {
						studentData.Name = studentName;
					});
					set(data);
				},
				setCourse: (course) =>
					set((state) => {
						state.Courses[course.Name] = course;
					}),
				setStudyTrack: (track, courses) =>
					set((state) => {
						state.StudyTracks[track] = { Courses: courses };
					}),
				setStudent: (student, data) =>
					set((state) => {
						const studentData = state.Students[student] || {};
						state.Students[student] = { ...studentData, ...data };
					}),
				setTeacher: (teacher, data) =>
					set((state) => {
						const teacherData = state.Teachers[teacher] || {};
						state.Teachers[teacher] = { ...teacherData, ...data };
					}),
				setTeacherConstraint: (teacher, constraint, index) =>
					set((state) => {
						const teacherData = state.Teachers[teacher]
						if (!teacherData.constraints) teacherData.constraints = [];
						if (constraint === null) {
							teacherData.constraints.splice(index, 1);
						} else {
							teacherData.constraints[index] = constraint;
						}
					}),
			}))
		)
	)
);

export interface SolutionDataState extends DataState {
	Lectures: { [name: string]: Lecture };
	Students: { [name: string]: Required<Student> };
}

export type SolutionResponse = {
	lectures: RawLectureData[];
	students: StudentResponseData[];
};
type RawLectureData = {
	name: string;
	course: string;
	classroom: string;
	teacher: string;
	start: number;
	end: number;
	day: number;
	students: string[];
};
type StudentResponseData = {
	name: string;
	lectures: string[];
	friends: string[];
	enemies: string[];
};

export const LOAD_STATES = {
	UNLOADED: 0,
	FETCHING: 1,
	DONE: 2,
	ERROR: -1,
} as const;

interface _SolutionStoreData {
	timestamp: number;
	loadState: Valueof<typeof LOAD_STATES>;
	updateLoadState: (ls: Valueof<typeof LOAD_STATES>) => void;
	initResponse: (res: SolutionResponse) => void;
}
interface UnSolvedSolutionStore extends _SolutionStoreData {
	solved: false;
	solution: null;
}
interface SolvedSolutionStore extends _SolutionStoreData {
	solved: true;
	solution: SolutionDataState;
}
type SolutionStoreData = SolvedSolutionStore | UnSolvedSolutionStore;

export const useSolutionStore = create<SolutionStoreData>()(
	logger(
		immer((set) => ({
			solved: false,
			timestamp: 0,
			loadState: LOAD_STATES.UNLOADED,
			solution: null,
			updateLoadState: (ls) => set({ loadState: ls }),
			initResponse: (res) => {
				const inputState = useDataStore.getState();
				let state = _.pick(inputState, [
					'Classrooms',
					'Courses',
					'StudyTracks',
					'Teachers',
					'Students',
				]);
				let lectures: SolutionDataState['Lectures'] = {};

				// Set up the lectures
				res.lectures.forEach((lect) => {
					lectures[lect.name] = {
						name: lect.name,
						course: lect.course,
						classroom: lect.classroom,
						teacher: lect.teacher,
						timeSlot: _.pick(lect, ['day', 'start', 'end']),
						studentNames: lect.students,
					};
				});

				// Set up the students
				let students: SolutionDataState['Students'] = {};
				res.students.forEach((student) => {
					students[student.name] = {
						...state.Students[student.name],
						Lectures: _.map(student.lectures, (lecture) => {
							return lectures[lecture].name;
						}),
						Name: student.name,
						Friends: student.friends,
						Enemies: student.enemies,
					};
				});
				set({
					solved: true,
					solution: { ...state, Students: students, Lectures: lectures } as SolutionDataState,
					loadState: LOAD_STATES.DONE,
					timestamp: useDataStore.getState().timestamp,
				});
			},
		}))
	)
);
